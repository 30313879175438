import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import camaro from "src/images/pages/wrapping/camaro.jpg"
import fiesta from "src/images/pages/wrapping/fiesta.png"
import firetruck from "src/images/pages/wrapping/firetruck1.jpg"
import { connect } from "react-redux"

const Wrapping = ({isMobile}) => (
  <Layout>
    <SEO title="Oklejanie, Oznakowanie pojazdów - Departament Reklamy"
         keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={4} iconBg={divisions.wrapping.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "Oznakowanie pojazdów",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Doświadczenie", id: "camaro" },
          { title: "Samochody specjalne", id: "samochody-specjalne" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Zwracaj na siebie uwagę",
        img: fiesta,
        texts: {
          1: {
            title: "TRWAŁE, NA ZAWSZE WYTRZYMAŁE",
            desc: "Każdemu klientowi zapewniamy najlepsze dopasowanie techniki wykonania oraz dobranie najlepszej " +
            "folii z naszego szerokiego asortymentu.",
          },
          2: {
            title: "NASZE DOŚWIADCZENIE MA ZNACZENIE",
            desc: "Oklejanie pojazdów jest jedną z naszych flagowych usług. Nasze doświadczenie nieprzerwalnie rośnie " +
            "od początku funkcjonowania naszej firmy. Jesteśmy fachowcami w tej profesji.",
          },
          3: {
            title: "NAJWYŻSZA JAKOŚĆ WYKONANIA",
            desc: "Klienci korzystajacy z naszych usług cieszą się precyzyjnością i poziomem wykonania tworzonych przez nas oklejeń.",
          },
        },
      }}/>


      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        width: "100%",
        maxHeight: "100vh",
        backgroundImage: "url(" + camaro + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% auto",
      }} id="camaro">
        <div style={{height: "100vh"}}/>
      </div>



      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>



      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="samochody-specjalne">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + firetruck + ")",
          backgroundPosition: "top right",
          backgroundSize: "auto 100%",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Samochody specjalne</div>
          <div className={subpageStyles.subtitle}>Stajemy na wysokości zadania</div>
          <div className={subpageStyles.text}>
            Dzięki naszemu wieloletnieum doświadczeniu, z powodzeniem możemy zajmować się więcej niż profesjonalnym
            oznakowaniem samochodów specjlanych. Przy takich zleceniach musimy sprostać nie tylko wymaganiom stawianym
            przez klienta, ale również przez przepisy.
          </div>
        </div>
      </div>



      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>



      <PriceList id="cennik" content={{
        items: [
          { title: "Oznakowanie samochodu", desc: "indywidualna wycena", price: "- zł", prefix: "od " },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Wrapping)
